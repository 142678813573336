<template>
  <div class="ho-my-vket-card">
    <div class="content-wrapper">
      <slot name="top" />
      <component
        :is="isHaLink ? HaLink : 'div'"
        class="image-wrapper"
        :to="to"
        :redirect="redirect"
      >
        <slot name="thumbnail" />
        <div class="tag-area">
          <HmTag
            v-for="(tag, index) in tagList"
            :key="`tag-${index}`"
            class="tag"
            :category="tag.category"
            >{{ tag.text }}</HmTag
          >
        </div>
        <div class="left-bottom">
          <slot name="thumbnail-left-bottom" />
        </div>
        <div class="right-bottom">
          <slot name="thumbnail-right-bottom" />
        </div>
      </component>
      <div class="body">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ValueOf } from '@/utils/types/types'
import { TAG_TYPE } from '@/utils/constants'

type Props = {
  to?: string | null
  redirect?: boolean
  tagList?: {
    text: string
    category?: ValueOf<typeof TAG_TYPE>
  }[]
  aspectRatio?: string
}
const props = withDefaults(defineProps<Props>(), {
  to: null,
  redirect: false,
  tagList: () => [],
  aspectRatio: '1 / 1',
})

const isHaLink = computed(() => !!props.to)
const HaLink = resolveComponent('HaLink')
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-my-vket-card {
  height: 100%;
  overflow: hidden;

  @include m.sp {
    border-radius: 5px;
  }
}

.content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  @include m.sp {
    padding: 0;
  }
}

.image-wrapper {
  aspect-ratio: v-bind(aspectRatio);
  border-radius: 10px;
  box-shadow: inset 0 -40px 30px -20px rgb(17 24 39 / 50%);
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    background: linear-gradient(
      180deg,
      rgba(17, 24, 39, 0) 80%,
      rgba(17, 24, 39, 0.2) 100%
    );
    content: '';
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
  }

  :deep(.image) {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      animation: 0.3s ease forwards;
      filter: saturate(1.5) brightness(1.1);
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
  @include m.sp {
    border-radius: 5px;
    box-shadow: inset 0 -40px 30px -20px rgb(17 24 39/50%);
  }
}

.tag-area {
  bottom: v.space(2);
  left: v.space(2);
  position: absolute;
  z-index: 1;
  @include m.sp {
    bottom: 5px;
    left: 5px;
  }

  .tag {
    cursor: pointer;
  }

  > .tag + .tag {
    margin-left: v.space(2);
    @include m.sp {
      margin-left: 5px;
    }
  }
}

.left-bottom {
  bottom: v.space(2);
  left: v.space(2);
  position: absolute;
  z-index: 1;

  @include m.sp {
    bottom: 5px;
    left: 5px;
  }
}

.right-bottom {
  bottom: 4px;
  position: absolute;
  right: 8px;
  z-index: 1;

  @include m.sp {
    bottom: 5px;
    right: 5px;
  }
}

.body {
  padding-top: v.space(2);
  width: 100%;
  @include m.sp {
    padding: 0 5px 5px;
  }
}
</style>
